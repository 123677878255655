import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection";
import SupportSection from "./SupportSection";
import MeetLawizSection from "./MeetLawizSection";
import ProductFeatures from "./ProductFeatures";
import PricingSection from "./PricingSection";
import TryCaseMinister from "./TryCaseMinister";
import FAQSection from "./FAQSection";
import Footer from "./Footer";
import InsightsSection from "./InsightsSection";
import WhyCaseMinister from "./WhyCaseMinister";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Authcontext";
import { jwtDecode as jwt_decode } from "jwt-decode";
import axiosInstance from "../../utils/axiosInstance";

const NewLandingPage = () => {
  const [headline, setHeadline] = useState("");
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      /* global google */
      const initializeGoogleSignIn = () => {
        google.accounts.id.initialize({
          client_id:
            "246105724090-95arttgdnp0sohiaqqtvpctr875kassq.apps.googleusercontent.com",
          callback: googleSuccess,
        });
        google.accounts.id.prompt(); // Display the One Tap prompt
      };

      const googleSuccess = (response) => {
        const credentialResponseDecode = jwt_decode(response.credential);
        const id_token = response.credential;
        // Using axios for sending data to the server
        axiosInstance
          .post(
            "api/google-login/",
            { id_token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("Server response:", response);
            // sessionStorage.setItem("user-info", JSON.stringify(response.data));
            localStorage.setItem(
              "user-info",
              JSON.stringify(response.data.user)
            );
            localStorage.setItem("access_token", response.data.access);
            // sessionStorage.setItem("access_token", response.data.access);
            // Make sure your backend is sending this data
            // Call login function if you have one, or handle the login state here
            login();
            // navigate("/");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      // Load the Google Identity Services library and initialize it
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [navigate, isAuthenticated, login]);
  return (
    <div className="bg-white dark:bg-black dark:text-white">
      <HeroSection />
      <SupportSection />
      <MeetLawizSection />
      <WhyCaseMinister />
      <ProductFeatures />
      <InsightsSection />
      <PricingSection />
      <TryCaseMinister />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default NewLandingPage;
