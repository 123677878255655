import React from "react";

// import AbousUsBG from "../../assets/images/aboutus/AboutUsBG.png";
// import AboutUsCM from "../../assets/images/aboutus/AboutUsCM.png";
import WhoWeAre1 from "../../assets/images/aboutus/WhoWeAre1.svg";
import WhoWeAre2 from "../../assets/images/aboutus/WhoWeAre2.svg";
import WhoWeAre3 from "../../assets/images/aboutus/WhoWeAre3.svg";
import TryCaseMinister from "../NewLandingPage/TryCaseMinister";
import Footer from "../NewLandingPage/Footer";
import { Link } from "react-router-dom";

const NewAboutUs = () => {
  return (
    <div className="pt-16">
      <div className="mt-8 flex flex-col justify-center">
        <div className="flex max-sm:flex-col gap-8 justify-center pl-10 pr-5 mb-16">
          <div className="max-w-[550px]">
            <img
              src="https://caseminister.blob.core.windows.net/frontend/AboutUsBG.png"
              alt="background"
              width="550px"
            />
          </div>
          <div className="max-w-[650px] flex flex-col gap-6 items-start">
            <h1 className="font-poppins font-semibold text-6xl leading-[96px] max-sm:text-2xl">
              Empowering Legal Professionals with AI-Powered Solutions
            </h1>
            <p className="font-poppins">
              Revolutionizing legal workflows with innovative AI tools to
              simplify processes, save time, and enhance productivity
            </p>
            <Link
              to="/signup"
              className="px-8 py-3 rounded-[50px] bg-[#7C4EE4] font-poppins font-bold text-white"
            >
              Join us
            </Link>
          </div>
        </div>
        <div className="relative">
          <div className="bg-[#F4F4F4] dark:bg-black h-[325px] max-sm:hidden"></div>
          <div className="bg-black py-10 lg:h-[325px] max-sm:hidden"></div>
          <div className="absolute max-sm:static max-sm:gap-8 max-sm:left-0 max-sm:top-0 max-sm:translate-y-0 max-sm:translate-x-0 max-sm:w-full dark:bg-black max-sm:flex-col max-sm:px-6 justify-center gap-20 items-center top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-1 w-[95%] bg-white px-16 py-10">
            <div className="flex flex-col items-start gap-6 max-w-[700px]">
              <p className="font-poppins text-7xl max-sm:text-5xl font-semibold dark:text-white">
                About us
              </p>
              <p className="font-poppins font-normal max-sm:text-base text-[#454545] dark:text-white mb-4 text-xl leading-[1.35]">
                At CaseMinister, we believe that the law should be accessible,
                understandable, and manageable for everyone. Whether you're an
                individual navigating a legal challenge or a legal professional
                seeking efficiency, our mission is to simplify the complex world
                of law using the power of artificial intelligence.
              </p>
              <Link
                to="/contactus"
                className="px-8 py-3 items-center rounded-[50px] bg-[#7C4EE4] font-poppins font-bold text-white"
              >
                Contact Us
              </Link>
            </div>
            <div className="flex-shrink-0">
              <img
                src="https://caseminister.blob.core.windows.net/frontend/AboutUsCM.png"
                className="dark:invert-[1] max-sm:max-w-[300px]"
                alt="case minister logo"
              />
            </div>
          </div>
        </div>
        <div className="px-16 py-20 max-sm:px-6 flex flex-col gap-16 bg-[#F4F4F4] dark:bg-black">
          <div className="flex max-w-[1440px] mx-auto flex-col gap-6 justify-center text-center">
            <p className="font-poppins text-6xl max-sm:text-5xl font-semibold">
              Who We Are
            </p>
            <p className="font-poppins font-normal text-xl max-sm:text-base">
              CaseMinister is an AI-driven legal tech platform built by a
              passionate team of legal experts, technologists, and innovators.
              We are committed to reshaping how legal services are delivered by
              making them more transparent, data-driven, and user-friendly.
            </p>
          </div>
          <div className="flex max-w-[1440px] mx-auto max-sm:flex-col max-sm:items-center justify-between gap-20">
            <div className="flex bg-white dark:bg-slate-950/80 flex-col gap-4 items-center border-y-[16px] max-w-[350px] px-6 py-10">
              <img src={WhoWeAre1} alt="decorative" className="max-w-[100px]" />
              <p className="font-poppins font-bold text-2xl mb-6">
                Our Mission
              </p>
              <ul className="list-disc flex flex-col gap-4">
                <li>Simplify complex legal processes with AI-driven tools.</li>
                <li>
                  Empower businesses and individuals with innovative solutions.
                </li>
                <li>
                  Enhance accessibility and transparency in legal systems.
                </li>
                <li>
                  Improve efficiency and accuracy in legal workflows through
                  cutting-edge technology.
                </li>
              </ul>
            </div>
            <div className="flex bg-white dark:bg-slate-950/80 flex-col gap-4 items-center border-y-[16px] max-w-[350px] px-6 py-10">
              <img src={WhoWeAre2} alt="decorative" className="max-w-[100px]" />
              <p className="font-poppins font-bold text-2xl mb-6">Our Vision</p>
              <ul className="list-disc flex flex-col gap-4">
                <li>
                  To become the go-to legal companion for individuals,
                  businesses, and legal professionals — providing clarity,
                  confidence, and control in every legal matter.
                </li>
              </ul>
            </div>
            <div className="flex flex-col bg-white dark:bg-slate-950/80 gap-4 items-center border-y-[16px] max-w-[350px] px-6 py-10">
              <img
                src={WhoWeAre3}
                alt="decorative"
                className="max-w-[100px] h-[72px]"
              />
              <p className="font-poppins font-bold text-2xl mb-6">Our Values</p>
              <ul className="list-disc flex flex-col gap-4">
                <li>
                  Accessibility: We break down legal barriers for everyone.
                </li>
                <li>
                  Innovation: We harness the latest in AI and data science.
                </li>
                <li>
                  Trust: We prioritize privacy, accuracy, and reliability.
                </li>
                <li>
                  Empowerment: We put knowledge and control in the
                  hands of our users.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="px-24 pt-24 pb-40 max-sm:px-6 flex max-w-[1440px] mx-auto flex-col gap-16">
          <div className="text-center">
            <p className="font-poppins text-6xl max-sm:text-5xl font-semibold">
              What We Do
            </p>
          </div>
          <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-x-20 gap-y-10">
            <div className="flex items-center gap-4">
              <p className="font-poppins min-w-[73.48px] text-9xl text-[#4B4B4B80] dark:text-[#4b4b4b] font-semibold">
                1
              </p>
              <div className="flex flex-col gap-4">
                <p className="font-poppins font-bold text-3xl">AI Chatbot</p>
                <p className="text-lg font-poppins">
                  Provides Real-time assistance.
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <p className="font-poppins text-9xl text-[#4B4B4B80] dark:text-[#4b4b4b] font-semibold">
                2
              </p>
              <div className="flex flex-col gap-4">
                <p className="font-poppins font-bold text-3xl">Case Tracking</p>
                <p className="text-lg font-poppins">
                  Stay informed with instant updates on ongoing cases.
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <p className="font-poppins text-9xl text-[#4B4B4B80] dark:text-[#4b4b4b] font-semibold">
                3
              </p>
              <div className="flex flex-col gap-4">
                <p className="font-poppins font-bold text-3xl">
                  Document Analysis
                </p>
                <p className="text-lg font-poppins">
                  Emphasizes automation for reviews and insights.
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <p className="font-poppins text-9xl text-[#4B4B4B80] dark:text-[#4b4b4b] font-semibold">
                4
              </p>
              <div className="flex flex-col gap-4">
                <p className="font-poppins font-bold text-3xl">
                  Judgement Search
                </p>
                <p className="text-lg font-poppins">
                  Effortlessly search case details, including petitioner,
                  respondent, and trial outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <TryCaseMinister />
        <Footer />
      </div>
    </div>
  );
};

export default NewAboutUs;
