import React, { useState, useEffect } from "react";
import "../../assets/css/NewLogin.css";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import google from "../../assets/images/signup/google_2702602.png";
import { jwtDecode as jwt_decode } from "jwt-decode";
import axiosInstance from "../../utils/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Authcontext";
import logo from "../../assets/images/navbar/legal-tech-logo.svg";
import { ToastContainer, toast } from "react-toastify";
import leftimage from "../../assets/images/signup/left-login-image.svg";
import loginvec from "../../assets/images/signup/login-back-vector.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import SingupImage from "../../assets/images/signup/SignupImage.png";
import DarkSignupImage from "../../assets/images/signup/DarkSignupImage copy.png";

const NewLoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedRememberMe = localStorage.getItem("rememberMe");
    const storedEmail = localStorage.getItem("rememberedEmail");
    const storedPassword = localStorage.getItem("rememberedPassword");

    if (storedRememberMe === "true" && storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const authHandler = (err, data) => {
    console.log(err, data);
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  const responseFacebook = (response) => {
    console.log(response);
  };

  const handleFacebookLoginSuccess = (response) => {
    // Handle successful Facebook login
    console.log("Facebook login successful:", response);
    // Call additional callback function if needed
    additionalCallbackFunction(response);
  };

  const handleFacebookLoginFailure = (error) => {
    // Handle failed Facebook login
    console.error("Facebook login failed:", error);
    // Call additional callback function if needed
    additionalCallbackFunction(null, error);
  };

  const additionalCallbackFunction = (response, error) => {
    // Additional logic based on Facebook login result
    if (response) {
      // Perform actions for successful login
    } else {
      // Perform actions for failed login or error
    }
  };

  //Reset Password API Call
  const handleResetPassword = async (e) => {
    if (email.includes("@")) {
      try {
        const resetPasswordResponse = await axiosInstance.post(
          "api/password_reset/",
          { email },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            withCredentials: true,
          }
        );

        console.log(resetPasswordResponse.data.detail);
        if (resetPasswordResponse.status === 200) {
          toast.success(resetPasswordResponse.data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      toast.error("Please enter the valid email address.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // const handleResetPassword = () => {};

  // Logging In API Call
  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!email.includes("@")) {
      toast.error("Please enter the valid email address.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!password.trim()) {
      toast.error("Please fill in the password field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    // setError(null);
    setMessage("Logging in...");

    try {
      // const lowerCaseEmail = email.toLowerCase();
      const result = await axiosInstance.post(
        "api/signin/",
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );
      // sessionStorage.setItem('user-info', JSON.stringify(result.data));
      // sessionStorage.setItem("access_token", result.data.access);
      localStorage.setItem("access_token", result.data.access);

      if (rememberMe) {
        localStorage.setItem("rememberedEmail", email);
        localStorage.setItem("rememberedPassword", password);
        localStorage.setItem("rememberMe", "true");
      } else {
        localStorage.removeItem("rememberedEmail");
        localStorage.removeItem("rememberedPassword");
        localStorage.setItem("rememberMe", "false");
      }

      // const access_token = localStorage.getItem("access_token");
      const access_token = localStorage.getItem("access_token");
      const profileresult = await axiosInstance.get("api/profile/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          Accept: "application/json",
        },
      });
      // sessionStorage.setItem("user-info", JSON.stringify(profileresult.data));
      localStorage.setItem("user-info", JSON.stringify(profileresult.data));
      // const intendedURL = sessionStorage.getItem("intendedURL");
      // sessionStorage.removeItem("intendedURL");

      // IntendedURL -
      const intendedURL = sessionStorage.getItem("intendedURL") || "/";
      sessionStorage.removeItem("intendedURL"); // Clear it

      login();
      // navigate("/");
      navigate(intendedURL);
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.detail);
      if (error.response.status === 403) {
        navigate("/wait-list");
      }
      if (error.response) {
        toast.error(error.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // setError('Account does not exist with provided detail');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const googleSuccess = (response) => {
    console.log(response);
    const credentialResponseDecode = jwt_decode(response.credential);
    const id_token = response.credential;
    // Using axios for sending data to the server
    axiosInstance
      .post(
        "api/google-login/",
        { id_token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Server response:", response);
        // sessionStorage.setItem("user-info", JSON.stringify(response.data));
        if (response.data.access) {
          localStorage.setItem("user-info", JSON.stringify(response.data.user));
          // sessionStorage.setItem("access_token", response.data.access);
          localStorage.setItem("access_token", response.data.access);
        }
        // Make sure your backend is sending this data
        // const intendedURL = sessionStorage.getItem("intendedURL");
        // sessionStorage.removeItem("intendedURL");
        // IntendedURL -
        const intendedURL = sessionStorage.getItem("intendedURL") || "/";
        sessionStorage.removeItem("intendedURL"); // Clear it

        login();
        // navigate("/");
        navigate(intendedURL);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const googleFailure = (error) => {
    toast.error("Google sign in failed. Please try again.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const loginwithgoogle = GoogleLogin({
    onSuccess: googleSuccess,
    onError: (error) => console.error("Google Login Error:", error),
  });
  return (
    <div className="pt-16">
      <div className="mt-8">
        <div className="flex max-md:gap-6 justify-center max-md:flex-col max-md:items-center">
          <div className="">
            <div className="relative max-w-[500px] max-sm:hidden">
              <img
                // src={SingupImage}
                src="https://caseminister.blob.core.windows.net/frontend/SignupImage.png"
                alt="decorative"
                className="w-[500px] dark:hidden"
              />
              <img
                // src={DarkSignupImage}
                src="https://caseminister.blob.core.windows.net/frontend/DarkSignupImage%20copy.png"
                alt="decorative"
                className="w-[500px] hidden dark:block"
              />
            </div>
          </div>
          <div className="border border-l-0 flex flex-col items-center max-sm:border-0 border-gray-500/10">
            <div className="Login-upper-section mt-10 max-sm:mt-0 text-left">
              {/* <img src={logo} alt="" /> */}
              <h1 className="dark:text-gray-200 font-poppins text-4xl font-medium">
                <span className="dark:text-gray-200 ">Welcome</span> Back!
              </h1>
              <p className="dark:text-gray-300 font-sora text-sm font-light">
                Please enter your Login credentials. It’s quick and easy.
              </p>
            </div>
            <div className="px-16 max-sm:px-5 justify-center flex flex-col items-center text-center">
              <div className="background-image"></div>
              <div className="centered-box max-sm:mt-0 max-sm:pt-0 max-sm:px-0">
                <div className="mobile-upper-section">
                  <h5 className="dark:text-gray-200 text-3xl font-poppins">
                    <span className="dark:text-gray-200">Welcome</span> Back!
                  </h5>
                  <p className="dark:text-gray-300 font-light font-sora mb-6 text-sm">
                    Please enter your Login credentials. It’s quick and easy.
                  </p>
                </div>
                <div className="login-container">
                  <div className="google-button mt-3">
                    <GoogleLogin
                      buttonText="Login with Google"
                      onSuccess={googleSuccess}
                      onError={googleFailure}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            maxWidth: "400px",
                            height: "50px",
                            color: "white",
                            border: "none",
                            borderRadius: "25px",
                            padding: "0 25px",
                            fontSize: "16px",
                            cursor: "pointer",
                            boxShadow: "0 2px 4px rgba(0,0,0,0.25)",
                            margin: "10px 0",
                          }}
                        >
                          <img
                            src={google}
                            alt="Google Icon"
                            style={{
                              marginRight: "12px",
                              height: "24px",
                              width: "24px",
                            }}
                          />
                          <span className="google-text">
                            Sign in With Google
                          </span>
                        </button>
                      )}
                    />
                  </div>
                </div>
                <div className="or-login-container">
                  <span className="line dark:invert-[1]"></span>
                  <span className="or-text dark:text-gray-200">or</span>
                  <span className="line dark:invert-[1]"></span>
                </div>
                <form className="login-form">
                  <div className="form-group">
                    <label className="dark:text-gray-300" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="example@gmail.com"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="border  border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                    />
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label className="dark:text-gray-300" htmlFor="password">
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="border border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                      style={{
                        // backgroundColor: "white",
                        // color: "black",
                        paddingRight: "40px", // to make space for the icon
                        borderRadius: "5px",
                      }}
                      required
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(25%)",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="dark:text-white" />
                      ) : (
                        <FaEye className="dark:text-white" />
                      )}
                    </span>
                  </div>
                  {/* <div className="form-options">
                                <label>
                                    <input
                                        type="checkbox"
                                        name="remember"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    /> Remember Me
                                </label>
                                <div className="forgot-password">
                                    <a href="#"><span style={{ color: '#000' }}>Forgot Password?</span></a><a href="#">Reset Password</a>
                                </div>
                            </div> */}
                  <div className="form-options">
                    <label className="login-checkbox-container flex items-center gap-10">
                      {/* <input
                    type="checkbox"
                    name="terms"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span className="login-checkbox-custom"></span>
                  <span className="login-checkbox-text dark:text-gray-200">
                    Remember Me
                  </span> */}
                    </label>
                    <div className="forgot-password">
                      {/* <a href="#">
                    <span style={{ color: "#000" }}>Forgot Password?</span>
                  </a> */}
                      <p
                        onClick={handleResetPassword}
                        className="cursor-pointer mb-2"
                      >
                        <span
                          className="dark:text-gray-200"
                          style={{ fontWeight: "600" }}
                        >
                          Forgot Password?
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="form-footer">
                    {/* <button type="submit" onClick={handleSignIn}>Log In</button> */}
                    <button
                      type="submit"
                      onClick={handleSignIn}
                      disabled={isLoading}
                      className="max-sm:text-xs w-full px-5 py-2 rounded-[10px] text-sm bg-[#7C4EE4] text-white font-bold"
                    >
                      {isLoading ? <div className="loader"></div> : "Log In"}
                    </button>
                    <div className="form-footer mt-5 mb-2">
                      <p>
                        <span className="dark:text-gray-200">
                          Don't have an account ?{" "}
                        </span>
                        <Link to="/signup">
                          <span
                            style={{ fontWeight: "600" }}
                            className="text-gray-900 font-bold dark:text-gray-300 hover:underline"
                          >
                            Signup here
                          </span>
                        </Link>
                      </p>
                    </div>
                    <p
                      style={{ marginBottom: "0px" }}
                      className="dark:text-gray-200"
                    >
                      By clicking on Log In, you agree to our{" "}
                      <Link className="font-bold" to="/terms-and-conditions">
                        Terms of Service
                      </Link>{" "}
                      &{" "}
                      <Link className="font-bold" to="/privacy-policy">
                        {" "}
                        Privacy Policy.
                      </Link>
                    </p>
                  </div>
                </form>
                {/* <div className="bottom-img">
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/left-login-image.svg"
                alt="Decorative"
                className="login-left-image dark:opacity-90"
              />
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLoginPage;
